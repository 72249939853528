import React from 'react';
import { Card, CardContent, CardMedia, Typography, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete'; // Import Delete icon

const BatchPoint = ({ title, subtitle, lat, lng, image, handleDeletePoint }) => {
  return (
    <Card sx={{ position: 'relative' }}>
      {/* Display the image if imageUrl is provided */}
      {image && (
        <CardMedia
          component="img"
          height="140"
          image={`${process.env.REACT_APP_PUBLIC_SERVER_ASSETS}/${image.id}`} // Ensure image is a valid URL
          alt={title}
        />
      )}
      <CardContent>
        {/* Title */}
        <Typography gutterBottom variant="h6" component="div">
          {title}
        </Typography>
        {/* Subtitle */}
        <Typography variant="body2" color="text.secondary">
          {subtitle}
        </Typography>
        {/* Latitude and Longitude */}
        <Typography variant="body2" sx={{ mt: 1 }}>
          <strong>Lat:</strong> {lat.toFixed(5)} <strong>Lon:</strong> {lng.toFixed(5)}
        </Typography>
      </CardContent>

      {/* Delete Button (positioned on top-right) */}
      <IconButton
        sx={{ position: 'absolute', top: 8, right: 8 }} // Position the button
        onClick={handleDeletePoint} // Call delete handler when clicked
      >
        <DeleteIcon />
      </IconButton>
    </Card>
  );
};

export default BatchPoint;