import React from "react";

function Timeline(props) {
  const { children } = props;

  // Sort the children array by `props.id`
  const sortedChildren = React.Children.toArray(children).sort((a, b) => {
    return a.props.id - b.props.id; // Compare `props.id` of each child
  });

  return <div className="timeline">{sortedChildren}</div>;
}

export default Timeline;