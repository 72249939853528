import React from "react";
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete"; // Import Delete Icon

const BatchEvidence = ({ title, subtitle, icon, handleDeleteEvidence }) => {
  return (
    <ListItem
      alignItems="flex-start"
      sx={{
        border: "1px solid",
        borderColor: "grey.300",
        borderRadius: 2,
        mb: 1, // margin-bottom for spacing between items
      }}
      secondaryAction={
        handleDeleteEvidence && (
          <IconButton edge="end" aria-label="delete" onClick={handleDeleteEvidence}>
            <DeleteIcon /> {/* Use DeleteIcon instead of EditIcon */}
          </IconButton>
        )
      }
    >
      {icon && (
        <ListItemAvatar>
          <Avatar
            src={`${process.env.REACT_APP_PUBLIC_SERVER_ASSETS}/${icon.id}`}
            alt={title}
            sx={{ width: 32, height: 32 }}
          />
        </ListItemAvatar>
      )}
      <ListItemText
        primary={title}
        secondary={subtitle}
        primaryTypographyProps={{ variant: "subtitle1" }}
        secondaryTypographyProps={{ color: "text.secondary" }}
      />
    </ListItem>
  );
};

export default BatchEvidence;