import React, { useEffect, useState, useRef } from "react";
import arrowLeft from "../../../Assets/ArrowLeft.svg";
import {
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GET_QRS,
  GET_QR_BY_NAME,
  UPDATE_QR,
} from "../../../graphql/getQrs";
import { IconButton, Snackbar, Alert, Tooltip, Typography, Button } from "@mui/material";
import { Download } from "@mui/icons-material"; // Import the copy icon
import { GET_STAGES } from "../../../graphql/trace/getStages";
import { GET_QR_BATCHES } from "../../../graphql/trace/getQrBatches";
import EditFormItem from "../Trace/EditFormItem";
import Timeline from "../Trace/Timeline";
import {QRCodeCanvas} from 'qrcode.react';

const EditQR = () => {
  const { qrId } = useParams();
  const history = useHistory();
  const location = useLocation();

  // Extract productId from query parameters
  const productId = new URLSearchParams(location.search).get("productId");

  // Fetch product data
  const { data: productData } = useQuery(GET_QRS, {
    variables: { id: productId },
  });

  // Extract mapId from product data
  const mapId = productData?.product_by_id?.map?.[0]?.id || null;
  const QRurl = productData?.product_by_id?.website_url ;
  // Fetch stages associated with the product
  const { data: stagesData } = useQuery(GET_STAGES, {
    variables: { id: productId },
  });

  const stages = stagesData?.product_by_id?.stages || [];

  // Fetch batches associated with the QR code
  const { data: qrData, refetch } = useQuery(GET_QR_BATCHES, {
    variables: { id: qrId },
  });

  //const batches = qrData?.qr_by_id?.batches || [];

  const batches = [...(qrData?.qr_by_id?.batches || [])].sort((a, b) => {
    const sortA = a.stage_id?.sort ?? 0;
    const sortB = b.stage_id?.sort ?? 0;
  
    return sortA - sortB;
  });
  
  const qrNameFromData = qrData?.qr_by_id?.name || "";

  // State for QR name
  const [qrName, setQrName] = useState(qrNameFromData);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  // Ref to access the QR code canvas
  const qrCanvasRef = useRef();
  
  // Queries and mutations
  const [getQR] = useLazyQuery(GET_QR_BY_NAME);
  const [updateQR] = useMutation(UPDATE_QR); //Cambia el nombre del QR

  // Update qrName state when qrNameFromData changes
  useEffect(() => {
    setQrName(qrNameFromData);
  }, [qrNameFromData]);

  // Handler for editing QR name
  const handleEditName = async () => {
    try {
      const { data: response } = await getQR({
        variables: { id: productId, name: qrName },
      });

      if (response && response.product_by_id?.qrs?.length <= 0) {
        await updateQR({
          variables: {
            id: qrId,
            data: { name: qrName },
          },
        });
        setSnackbarMessage("El ID se modificó correctamente");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
      } else {
        setSnackbarMessage("Este ID ya existe.");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error al actualizar el nombre del QR:", error);
      setSnackbarMessage(
        "Hubo un error al modificar el ID. Por favor, inténtalo de nuevo."
      );
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  // Ref to access the QR Code SVG element
  const qrCodeRef = useRef(null);
   // Handle PNG download
   const handleDownload = () => {
    const canvas = qrCanvasRef.current.querySelector("canvas");
    if (canvas) {
      const pngUrl = canvas.toDataURL("image/png");

      // Create a temporary <a> element to trigger the download
      const link = document.createElement("a");
      link.href = pngUrl;
      link.download = `qr-${qrName || "default"}.png`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setSnackbarMessage("Descarga de QR como PNG completada.");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
    } else {
      console.error("Canvas element not found.");
      setSnackbarMessage("Error al generar la descarga.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };
 
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-7">
          {/* Top Navigation */}
          <Tooltip title="Regresar" sx={{ mr: 2, mb: 1, pl: 0 }}>
            <IconButton onClick={() => history.goBack()}>
              <img src={arrowLeft} alt="back-icon" width={24} />
            </IconButton>
          </Tooltip>

          <Typography variant="h5" >Editar QR {qrName && ` ${qrName}`}</Typography>
          <Typography variant="subtitle1" color="text.secondary" gutterBottom sx={{ display: 'block' }}>
              El fin de este ID es poder identificar en base al QR impreso en el producto, por que estapas pasó hasta llegar a identificar su origen.
          </Typography>
        

          {/* QR ID Edit Form */}
          <div className="row">
            <div className="col">
              <div className="card my-3">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between p-1">
                    <div className="flex-fill">
                      <div className="input-group mb-1">
                        <span className="input-group-text">QR ID</span>
                        <input
                          value={qrName}
                          className="form-control"
                          name="batchNumber"
                          onChange={(e) => setQrName(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleEditName();
                            }
                          }}
                        />
                        <button
                          onClick={handleEditName}
                          className="btn btn-primary"
                        >
                          Editar ID
                        </button>
                      </div>
                      <Snackbar
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                      >
                        <Alert
                          onClose={handleCloseSnackbar}
                          severity={snackbarSeverity}
                          sx={{ width: "100%" }}
                        >
                          {snackbarMessage}
                        </Alert>
                      </Snackbar>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5 text-end mt-5">
          <div id="qr-canvas-container" ref={qrCanvasRef}>
            {/* Render QR Code as Canvas */}
            <QRCodeCanvas value={`${QRurl}?qr=${qrId}`} size={150} />
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownload}
            startIcon={<Download />}
            sx={{ marginTop: 2, marginRight: 1 }}
          >
            Descargar
          </Button>
        </div>
      </div>

      <div className="mt-5">
        <Timeline>
          
          {/* Render batches and stages */}
          {batches.length > 0 && stages.length > 0 ? (
            batches.map((batch) => {
              // Find the corresponding stage for this batch
              const stage = stages.find(
                (stage) => stage.id === batch.stage_id.id
              );

              if (!stage) {
                console.warn(
                  `Stage not found for batch with stage_id ${batch.stage_id.id}`
                );
                return null;
              }

              // Prepare batch evidences and points
              const batchEvidences =
                batch.evidences?.map((ev) => ({ evidence_id: ev })) || [];

              const batchPoints =
                batch.points?.map((point) => ({ evidence_id: point })) || [];

              // Combine stage and batch evidences
              const evidences =
                batch.evidences && batch.evidences.length > 0
                  ? [...(stage.evidences || []), ...batchEvidences]
                  : stage.evidences || [];

              return (
                <EditFormItem
                  key={batch.id}
                  batch={batch}
                  id={stage.id}
                  type={stage.type}
                  title={stage.title}
                  subtitle={stage.subtitle}
                  icon={stage.icon}
                  heading={stage.output}
                  location={stage.location}
                  outputIcon={stage.output_icon?.id}
                  by={stage.by}
                  logoBy={stage.logo_by?.id}
                  inputs={stage.inputs}
                  evidence={evidences}
                  batchEvidences={batchEvidences}
                  batchPoints={batchPoints}
                  refetch={refetch}
                  mapId={mapId}
                  productId={productId}
                  qrId={qrId}
                />
              );
            })
          ) : (
            <p>No batches or stages found.</p>
          )}
        </Timeline>
      </div>
    </div>
  );
};

export default EditQR;