import { useState } from "react";
import Verification from "./Verification";
import { useMutation } from "@apollo/client";
import { EDIT_BATCH, CREATE_BATCH, DELETE_BATCH_EVIDENCE, DELETE_BATCH_POINT } from "../../../graphql/trace/getQrBatches";
import CreateEvidence from "./CreateEvidence";
import BatchEvidence from "./BatchEvidence";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Grid, Box, Button, Typography, TextField } from "@mui/material";
import CreateMapPoint from "./CreateMapPoint";
import BatchPoint from "./BatchPoint";
import { CloudUpload, SaveAlt, Delete as DeleteIcon } from '@mui/icons-material'; // Import delete icon
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function EditFormItem(props) {
  const {
    id,
    title, // Header title
    subtitle, // Header subtitle
    icon, // Icon on the left
    heading, // Body heading
    location, // Body location
    outputIcon, // Product output icon (H:, W:)
    by, // The company that processes the product
    logoBy, // The logo of the processing company (optional)
    inputs, // What was used to make the final product
    evidence, // Array of evidence objects
    batch,
    batchEvidences,
    refetch,
    batchPoints,
    mapId,
  } = props;

  const { points } = batch;
  
  const [batchNumber, setBatchNumber] = useState(batch?.batch_number);
  const [description, setDescription] = useState(batch?.description || "");
  const [showDescription, setShowDescription] = useState(!!description); // Show only if there is a description
  const toggleDescriptionField = () => {
    setShowDescription(!showDescription);
  };
  const descriptionString = `${description}`;

  const [volume, setVolume] = useState(batch?.quantity);
  const [unit, setUnit] = useState(batch?.unit);
  const [locationBatch, setLocationBatch] = useState({
    lat: null,
    lng: null,
  });

  const [editBatch] = useMutation(EDIT_BATCH);
  const [createBatch] = useMutation(CREATE_BATCH);
  const [deleteEvidence] = useMutation(DELETE_BATCH_EVIDENCE); // Mutation for deleting evidence
  const [deletePoint] = useMutation(DELETE_BATCH_POINT); // Mutation for deleting a point

  const [show, setShow] = useState(false);
  const [showModalMapPoint, setShowModalMapPoint] = useState(false);

  const [edit, setEdit] = useState(false);
  const [evidenceToEdit, setEvidenceToEdit] = useState({});

  const handleClose = () => {
    setEdit(false);
    setEvidenceToEdit({});
    setShow(false);
    setShowModalMapPoint(false);
  };
  const handleShow = () => setShow(true);

  const { qrId } = useParams();

  const handleSubmitBatch = async () => {
    try {
      if (!!batch) {
        const data = {
          batch_number: batchNumber,
          quantity: +volume,
          unit: unit,
          description: description,
        };
        await editBatch({
          variables: {
            id: batch?.id,
            data: data,
          },
        });
        refetch();
        alert("El batch se editó correctamente");
      } else {
        const data = {
          batch_number: batchNumber,
          quantity: +volume,
          unit: unit,
          description: description,
          qr_id: {
            id: qrId,
          },
          stage_id: {
            id: id,
          },
        };
        await createBatch({
          variables: {
            data: data,
          },
        });
        refetch();
        alert("El batch se creó correctamente");
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // New delete evidence handler
  const handleDeleteEvidence = async (evidenceId) => {
    try {
      // Confirm before deleting
      const confirmDelete = window.confirm(
        "¿Estás seguro de que deseas eliminar esta evidencia?"
      );
      if (!confirmDelete) return;

      // Execute the deletion mutation
      await deleteEvidence({
        variables: {
          id: evidenceId, // ID of the evidence to delete
        },
      });

      // Refetch or update the list of evidences after deletion
      refetch();
      alert("Evidencia eliminada correctamente.");
    } catch (error) {
      console.error("Error al eliminar la evidencia:", error);
    }
  };

 // Delete point handler
 const handleDeletePoint = async (pointId) => {
  try {
    const confirmDelete = window.confirm(
      "¿Estás seguro de que deseas eliminar esta ubicación?"
    );
    if (!confirmDelete) return;

    await deletePoint({
      variables: { id: pointId }, // Assuming `pointId` is the identifier for the point to be deleted
    });
    refetch();
    alert("Ubicación eliminada correctamente.");
  } catch (error) {
    console.error("Error al eliminar la ubicación:", error);
  }
};


  const [age, setAge] = useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-7">
          <div className="card my-3">
            <div className="card-body">
              <div className="">
                <div className="flex-fill">
                  <p>Definir Lote: {title}</p>
                  <div className="input-group mb-3">
                    <span
                      className="input-group-text"
                      id="inputGroup-sizing-default"
                    >
                      #
                    </span>
                    <input
                      value={batchNumber}
                      className="form-control"
                      name="batchNumber"
                      onChange={(e) => setBatchNumber(e.target.value)}
                    />
                  </div>

                  <div className="d-flex mb-3">
                    <div className="input-group me-3">
                      <span
                        className="input-group-text"
                        id="inputGroup-sizing-default"
                      >
                        Cantidad
                      </span>
                      <input
                        value={volume}
                        type="number"
                        className="form-control"
                        name="volume"
                        onChange={(e) => setVolume(e.target.value)}
                      />
                    </div>
                    <div className="input-group">
                      <span
                        className="input-group-text"
                        id="inputGroup-sizing-default"
                      >
                        Unidad
                      </span>
                      <input
                        value={unit}
                        type="text"
                        className="form-control"
                        name="unit"
                        onChange={(e) => setUnit(e.target.value)}
                      />
                    </div>
                  </div>

                  {/* Toggle Button for Description */}
                  <Button
                    variant="text"
                    onClick={toggleDescriptionField}
                  >
                    {showDescription ? "Ocultar Descripción" : "Agregar una Descripción"}
                  </Button>
                  
                  {/* Conditionally Render Description Input */}
                  {showDescription && (
                    <ReactQuill
                      theme="snow"
                      value={description}
                      onChange={setDescription}
                      placeholder="Add a description..."
                    />
                  )}

                  {!!batch ? (
                    <>
                      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1} pt={2}>
                        {batchEvidences?.length ? (
                          <Typography variant="h6">Evidencias:</Typography>
                        ) : (
                          <Typography variant="h6">Sin evidencias</Typography>
                        )}
                        <Button
                          variant="text"
                          color="secondary"
                          onClick={handleShow}
                          startIcon={<CloudUpload />}
                        >
                          Nueva Evidencia
                        </Button>
                      </Box>

                      {/* Map through evidences and include Delete button */}
                      {batchEvidences?.map(({ evidence_id }, index) => (
                        <div key={index}>
                          <BatchEvidence
                            title={evidence_id.title}
                            subtitle={evidence_id.subtitle}
                            icon={evidence_id.icon}
                            handleDeleteEvidence={() => handleDeleteEvidence(evidence_id.id)} // Ensure delete handler is passed
                          />
                        </div>
                      ))}

                      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1} pt={2}>
                        {batchPoints?.length ? (
                          <Typography variant="h6">Ubicaciones:</Typography> 
                        ) : (
                          <Typography variant="h6">Sin ubicaciones</Typography>
                        )}
                        <Button
                          variant="text"
                          color="secondary"
                          onClick={() => setShowModalMapPoint(true)}
                          startIcon={<CloudUpload />}
                        >
                          Nueva Ubicación
                        </Button>
                      </Box>

                      <Grid container spacing={1}>
                        {batchPoints?.map(({ evidence_id }, index) => (
                          <Grid item xs={12} sm={6} key={index}>
                            <BatchPoint
                              title={evidence_id.nombre}
                              subtitle={evidence_id.descripcion}
                              image={evidence_id.image}
                              lat={evidence_id.location[0].lat}
                              lng={evidence_id.location[0].lng}
                              handleDeletePoint={() => handleDeletePoint(evidence_id.id)}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </>
                  ) : null}

                  <Box display="flex" justifyContent="flex-end" mt={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmitBatch}
                      startIcon={<SaveAlt />}
                    >
                      Guardar Cambios
                    </Button>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md">
          <div className="card border-light shadow my-3">
            <div className="card-header bg-transparent border-light">
              {title} <small className="text-muted">{batchNumber}</small>
            </div>
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                {outputIcon && (
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_SERVER_ASSETS}/${outputIcon}`}
                    className="m-2"
                    alt=""
                    width="60"
                    height="60"
                  />
                )}
                <div className="flex-fill">
                  <p className="card-title mb-0">
                    {volume && unit
                      ? `${volume} ${unit} de ${heading}`
                      : heading}
                  </p>
                  <p className="card-subtitle mb-2 text-muted">
                    <small>{location}</small>
                  </p>
                </div>
              </div>

              {inputs && (
                <div className="d-flex align-items-center justify-content-between">
                  <div className="flex-fill">
                    <p className="card-title text-muted mb-0">
                      <small>Hecho con</small>
                    </p>
                    <p className="card-subtitle fw-light mb-2">{inputs}</p>
                  </div>
                </div>
              )}

              {by && (
                <div className="d-flex align-items-center justify-content-between">
                  {logoBy && (
                    <img
                      src={`${process.env.REACT_APP_PUBLIC_SERVER_ASSETS}/${logoBy}`}
                      className="m-2"
                      alt=""
                      width="32"
                      height="32"
                    />
                  )}
                  <div className="flex-fill">
                    <p className="card-subtitle text-muted mb-0">
                      <small>Hecho por</small>
                    </p>
                    <p className="card-title mb-2">{by}</p>
                  </div>
                </div>
              )}

              <div dangerouslySetInnerHTML={{ __html: descriptionString }} />

              {evidence && <Verification id={id} data={evidence} />}
            </div>
          </div>
        </div>
      </div>

      {/* Modal for creating/editing evidence */}
      {show && (
        <CreateEvidence
          show={show}
          handleClose={handleClose}
          edit={edit}
          evidenceToEdit={evidenceToEdit}
          batchId={batch?.id}
          refetch={refetch}
        />
      )}

      {/* Modal for creating map points */}
      {showModalMapPoint && (
        <CreateMapPoint
          show={showModalMapPoint}
          handleClose={handleClose}
          batchId={batch?.id}
          mapId={mapId}
          refetch={refetch}
        />
      )}
    </>
  );
}

export default EditFormItem;